import React from 'react';
import translations from '../lib/translations';
import networkProviders from '../lib/helpers/network-providers';

import logo from '../images/energi.png';
import twitter from '../images/social-twitter.svg';
import twitterHover from '../images/social-twitter-hover.svg';
import instagram from '../images/social-instagram.svg';
import instagramHover from '../images/social-instagram-hover.svg';
import youtube from '../images/social-youtube.svg';
import youtubeHover from '../images/social-youtube-hover.svg';
import facebook from '../images/social-facebook.svg';
import facebookHover from '../images/social-facebook-hover.svg';
import linkedin from '../images/social-linkedin.svg';
import linkedinHover from '../images/social-linkedin-hover.svg';
import CollapsibleLink from './subpages/collapsible';

const Footer = props => {
    let staging;
    const translate = translations.init(props),
        propsVersion = props.version || props.pageContext.version,
        LINKS = [
            {
                title: 'MORE_ABOUT_US',
                content: [
                    {
                        text: 'WEBSITE',
                        link: 'https://www.energi.world/',
                        class: 'capitalize'
                    },
                    {
                        text: 'DOCUMENTATION',
                        link: 'https://wiki.energi.world',
                        class: 'capitalize'
                    },
                    {
                        text: 'JOIN_OUR_TEAM',
                        link: 'https://www.energi.world/join-our-team/',
                        class: 'capitalize'
                    },
                    {
                        text: 'CONTACT_US',
                        link: 'https://www.energi.world/contact-us/',
                        class: 'capitalize'
                    },
                    {
                        text: 'GITHUB',
                        link: 'https://github.com/energicryptocurrency',
                        class: ''
                    }
                ]
            },
            {
                title: 'PRODUCTS',
                content: [
                    {
                        text: 'MY_ENERGI_WALLET',
                        link: 'https://wallet.energi.network',
                        class: 'capitalize'
                    },
                    {
                        text: 'BLOCK_EXPLORER',
                        link: 'https://explorer.energi.network',
                        class: 'capitalize'
                    },
                    {
                        text: 'DOWNLOADS',
                        link: 'https://www.energi.world/wallets/',
                        class: 'capitalize'
                    }
                ]
            },
            {
                title: 'JOIN_COMMUNITY',
                content: [
                    {
                        text: 'DISCORD',
                        link: 'https://discordapp.com/invite/sCtgNC3',
                        class: 'capitalize'
                    },
                    {
                        text: 'TELEGRAM_CHAT',
                        link: 'https://t.me/energicrypto',
                        class: 'capitalize'
                    },
                    {
                        text: 'TELEGRAM_ANN_CHANNEL',
                        link: 'https://t.me/energiannouncements',
                        class: 'capitalize'
                    },
                    {
                        text: 'REDDIT',
                        link: 'https://www.reddit.com/r/energicryptocurrency',
                        class: 'capitalize'
                    },
                    {
                        text: 'BITCOINTALK',
                        link: 'https://bitcointalk.org/index.php?topic=4912743',
                        class: ''
                    }
                ]
            },
            {
                title: 'BLOG',
                content: [
                    {
                        text: 'MEDIUM',
                        link: 'https://medium.com/energi',
                        class: 'capitalize'
                    },
                    {
                        text: 'STEEMIT',
                        link: 'https://steemit.com/@energi',
                        class: 'capitalize'
                    }
                ]
            }
        ];
    let version, middle, middleMobile;

    if (propsVersion) {
        staging = networkProviders.isStaging ? ' testnet' : '';
        version = (<span className="version">v{propsVersion}{staging}</span>);
    }

    // define mobile:
    middleMobile = LINKS.map((linksData, index) => (
        <CollapsibleLink
            content="link-content"
            data={linksData}
            header="link-title" key={index}
            translate={translate}
            wrapper="link-block" />
    ));

    // define non mobile:
    middle = LINKS.map((block, index) => {
        return (
            <div className="pure-u-1-4 link-block" key={index}>
                <p className="title">{translate.t(block.title)}</p>
                {block.content.map((links, index) => <p key={index}><a className={links.class} href={links.link} rel="noopener noreferrer" target="_blank">{translate.t(links.text)}</a></p>)}
            </div>
        );
    });

    return (
        <div className="footer">
            <div className="social-media">
                <span className="no-mobile">{translate.t('FOLLOW_US', true)}:</span>
                <a href="https://twitter.com/energi" rel="noopener noreferrer" target="_blank">
                    <img
                        onMouseOut={e => e.target.src = twitter}
                        onMouseOver={e => e.target.src = twitterHover}
                        src={twitter} />
                </a>
                <a href="https://www.instagram.com/energicrypto" rel="noopener noreferrer" target="_blank">
                    <img
                        onMouseOut={e => e.target.src = instagram}
                        onMouseOver={e => e.target.src = instagramHover}
                        src={instagram} />
                </a>
                <a href="https://www.youtube.com/channel/UCCABQly0NNR2j_M_iDpy8mA" rel="noopener noreferrer" target="_blank">
                    <img
                        onMouseOut={e => e.target.src = youtube}
                        onMouseOver={e => e.target.src = youtubeHover}
                        src={youtube} />
                </a>
                <a href="https://www.facebook.com/energicrypto" rel="noopener noreferrer" target="_blank">
                    <img
                        onMouseOut={e => e.target.src = facebook}
                        onMouseOver={e => e.target.src = facebookHover}
                        src={facebook} />
                </a>
                <a href="https://www.linkedin.com/company/energi-core" rel="noopener noreferrer" target="_blank">
                    <img
                        onMouseOut={e => e.target.src = linkedin}
                        onMouseOver={e => e.target.src = linkedinHover}
                        src={linkedin} />
                </a>
            </div>

            <div className="pure-g link-wrapper no-mobile">
                {middle}
            </div>

            <div className="link-wrapper only-mobile">
                {middleMobile}
            </div>

            <div className="company">
                <div className="logo">
                    <img alt="logo" src={logo} />
                    <p>Energi</p>
                    <div className="after-logo">
                        <a href="https://www.energi.world/privacy-policy/" rel="noopener noreferrer" target="_blank">{translate.t('PRIVACY_POLICY')}</a>
                        <a href="https://www.energi.world/important-disclaimers/" rel="noopener noreferrer" target="_blank">{translate.t('DISCLAIMER', true)}</a>
                        {version}
                    </div>
                </div>
                <div className="faq">
                    <p dangerouslySetInnerHTML={{__html: translate.md('footer')}} />
                </div>
            </div>
        </div>
    );
};

export default Footer;
